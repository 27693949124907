import { Override } from "../types";
import { dateToStr } from "../utils/dates";
import {
  AssistType,
  DailyHabit as HabitDto,
  Event as EventDto,
  PlannerActionIntermediateResult,
  RsvpResponseBody as RsvpResponseBodyDto,
  Task as TaskDto,
  TaskOrHabit as TaskOrHabitDto,
} from "./client";
import { dtoToEvent, Event, EventResponseStatus, SnoozeOption } from "./Events";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToTask, Task } from "./Tasks";
import { NotificationKeyStatus, TransformDomain } from "./types";

export type NotificationResource = "Task" | "Habit" | "OneOnOne" | "Event";

export type TaskOrHabit = Override<
  TaskOrHabitDto,
  {
    title: string | null;
    location?: string | null;
  }
>;

export const isTask = (taskOrHabit: TaskOrHabit): taskOrHabit is TaskDto =>
  !!taskOrHabit && taskOrHabit.type === AssistType.TASK;
export const isHabit = (taskOrHabit: TaskOrHabit): taskOrHabit is HabitDto =>
  !!taskOrHabit && taskOrHabit.type !== AssistType.TASK;

export type PlannerActionResult = Override<
  PlannerActionIntermediateResult,
  {
    events: Event[];
    task?: Task;
    habit?: Habit;
  }
>;

export type PlannerActionQuery = {
  minutes?: number;
  eventId?: string;
  eventKey?: string;
  start?: Date;
  end?: Date;
  date?: Date;
  taskIndex?: number | null;
  enabled?: boolean;
  calendar?: number;
  rsvp?: RsvpResponseBody;
  // TODO (SS): Remove once new endpoint is ready https://linear.app/reclaim/issue/RAI-4419
  event?: Event;
};

export enum RsvpResponsePeriod {
  Single = "SINGLE",
  Forward = "FORWARD",
  All = "ALL",
}

export type RsvpResponseBody = Override<
  RsvpResponseBodyDto,
  {
    responseStatus: EventResponseStatus;
    period?: RsvpResponsePeriod | null;
  }
>;

export const fromDto = (dto: PlannerActionIntermediateResult): PlannerActionResult => {
  return {
    ...dto,
    events: dto.events.map((event: EventDto) => dtoToEvent(event)),
    task: isTask(dto.taskOrHabit as TaskOrHabit) ? dtoToTask(dto.taskOrHabit as TaskDto) : undefined,
    habit: isHabit(dto.taskOrHabit as TaskOrHabit) ? dtoToHabit(dto.taskOrHabit as HabitDto) : undefined,
  };
};

export class PlannerDomain extends TransformDomain<PlannerActionResult, PlannerActionIntermediateResult> {
  resource = "Planner";
  cacheKey = "planner";

  public deserialize = fromDto;

  private handleResult =
    (notificationKey?: string) =>
    (res: PlannerActionIntermediateResult): PlannerActionIntermediateResult => {
      if (!!notificationKey) this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);

      const result = this.deserialize(res);
      if (!!result?.task) this.client.tasks.upsert(result.task);
      if (!!result?.habit) this.client.habits.upsert(result.habit);
      if (!!result?.events) this.client.events.upsert(result.events);

      return res;
    };

  handleError = (reason, message: string, notificationKey?: string) => {
    console.warn("Request failed", message);

    if (!!notificationKey) {
      this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
    }

    throw new Error(message);
  };

  setupNotification = (id: number | string, resource?: NotificationResource): string => {
    let notificationKey = this.generateUid("planner", id);

    if (!!resource) {
      notificationKey = notificationKey.replace(this.resource, resource);
    }

    this.expectChange(notificationKey, id, {}, true);

    return notificationKey;
  };

  /**
   * Event Actions
   */
  moveEvent = this.manageErrors(
    this.deserializeResponse((eventId: string, start: string, end: string) => {
      const notificationKey = this.setupNotification(eventId);

      return this.api.planner
        .moveEvent(eventId, { start, end, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not move event", notificationKey));
    })
  );

  pinEvent = this.manageErrors(
    this.deserializeResponse((eventId: string) => {
      const notificationKey = this.setupNotification(eventId);

      return this.api.planner
        .pinEvent(eventId, { notificationKey })
        .then(this.handleResult())
        .catch((reason) => this.handleError(reason, "Request failed: Could not pin event", notificationKey));
    })
  );

  unpinEvent = this.manageErrors(
    this.deserializeResponse((eventId: string) => {
      const notificationKey = this.setupNotification(eventId);

      return this.api.planner
        .unpinEvent(eventId, { notificationKey })
        .then(this.handleResult())
        .catch((reason) => this.handleError(reason, "Request failed: Could not unpin event", notificationKey));
    })
  );

  rsvp = this.manageErrors(
    this.deserializeResponse((eventId: string, calendarId: number, rsvp: RsvpResponseBody) => {
      const notificationKey = this.setupNotification(eventId);

      return this.api.planner
        .rsvp(calendarId, eventId, rsvp, { notificationKey })
        .then(this.handleResult())
        .catch((reason) => this.handleError(reason, "Request failed: Could not rsvp to event", notificationKey));
    })
  );

  /**
   * Task Actions
   */

  // Adds time to the task policy
  addTime = this.manageErrors(
    this.deserializeResponse((id: number, minutes?: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .addTime(id, { minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend task", notificationKey));
    })
  );

  logWork = this.manageErrors(
    this.deserializeResponse((id: number, query?: Pick<PlannerActionQuery, "minutes" | "end">) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .logWork(id, { minutes: query?.minutes, end: dateToStr(query?.end), notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not log work for task", notificationKey));
    })
  );

  restartTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .restartTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not restart task", notificationKey));
    })
  );

  startTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .startTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not start task now", notificationKey));
    })
  );

  stopTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .stopTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not stop task", notificationKey));
    })
  );

  doneTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .doneTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark task as done", notificationKey));
    })
  );

  unarchiveTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .unarchiveTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not unarchive task", notificationKey));
    })
  );

  rescheduleTaskInstance = this.manageErrors(
    this.deserializeResponse((id: number, eventId: string) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .rescheduleTaskEvent(eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not reschedule task instance", notificationKey)
        );
    })
  );

  deleteTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Task");

      return this.api.planner
        .deleteTaskPolicy(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task", notificationKey));
    })
  );

  rescheduleTaskEvent = this.manageErrors(
    this.deserializeResponse((taskId: number, calendarId: number, eventId: string, snoozeOption?: SnoozeOption) => {
      const notificationKey = this.setupNotification(taskId, "Task");

      return this.api.planner
        .taskDeleteInstanceAndReschedule(calendarId, eventId, { notificationKey, snoozeOption })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not reschedule habit instance", notificationKey)
        );
    })
  );

  prioritizeTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      return this.api.planner
        .prioritizeTask(id)
        .then(this.handleResult())
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task"));
    })
  );

  clearSnooze = this.manageErrors(
    this.deserializeResponse((taskId: number) => {
      const notificationKey = this.setupNotification(taskId, "Task");

      return this.api.planner
        .taskClearSnoooze(taskId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not clear snooze for task", notificationKey)
        );
    })
  );

  /**
   * One on One Actions
   */

  oneOnOneReschedule = this.manageErrors(
    this.deserializeResponse((oneOnOneId: number, eventId: string) => {
      const notificationKey = this.setupNotification(oneOnOneId, "OneOnOne");

      return this.api.planner
        .oneOnOneReschedule(oneOnOneId, eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task", notificationKey));
    })
  );

  oneOnOneSkipDay = this.manageErrors(
    this.deserializeResponse((oneOnOneId: number, eventId: string) => {
      const notificationKey = this.setupNotification(oneOnOneId, "OneOnOne");

      return this.api.planner
        .oneOnOneSkipDay(oneOnOneId, eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task", notificationKey));
    })
  );

  oneOnOneSkipUntil = this.manageErrors(
    this.deserializeResponse((oneOnOneId: number, eventId: string) => {
      const notificationKey = this.setupNotification(oneOnOneId, "OneOnOne");

      return this.api.planner
        .oneOnOneSkipUntil(oneOnOneId, eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task", notificationKey));
    })
  );

  oneOnOneSkipWeek = this.manageErrors(
    this.deserializeResponse((oneOnOneId: number, eventId: string) => {
      const notificationKey = this.setupNotification(oneOnOneId, "OneOnOne");

      return this.api.planner
        .oneOnOneSkipWeek(oneOnOneId, eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete task", notificationKey));
    })
  );

  /**
   * Habit Actions
   */
  restartHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .restartHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not restart habit", notificationKey));
    })
  );

  startHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not start habit now", notificationKey));
    })
  );

  stopHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .stopHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not stop habit", notificationKey));
    })
  );

  doneHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .doneHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark habit as done", notificationKey));
    })
  );

  deleteHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .deleteHabitPolicy(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete habit", notificationKey));
    })
  );

  toggleHabit = this.manageErrors(
    this.deserializeResponse((id: number, enable?: boolean) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .toggleHabit(id, { enable, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not delete habit", notificationKey));
    })
  );

  rescheduleHabitInstance = this.manageErrors(
    this.deserializeResponse((id: number, eventId: string) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .rescheduleHabitEvent(eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not reschedule habit instance", notificationKey)
        );
    })
  );

  rescheduleHabitEvent = this.manageErrors(
    this.deserializeResponse((habitId: number, calendarId: number, eventId: string, snoozeOption?: SnoozeOption) => {
      const notificationKey = this.setupNotification(habitId, "Habit");

      return this.api.planner
        .habitDeleteInstanceAndReschedule(calendarId, eventId, { notificationKey, snoozeOption })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not reschedule habit instance", notificationKey)
        );
    })
  );

  skipHabitInstance = this.manageErrors(
    this.deserializeResponse((id: number, eventId: string) => {
      const notificationKey = this.setupNotification(id, "Habit");

      return this.api.planner
        .skipHabitEvent(eventId, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not skip habit instance", notificationKey));
    })
  );
}
